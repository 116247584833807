<template>

  <!-- Back Ground for popups. Only need one of these -->
  <div
    class="popup_overlay"
    style="display: none;"
  >
&nbsp;
  </div>

  <!--TODO: this freaks me out. We are killing bindings? Hopefully this just applies to this element.-->
  <div
    id="setup_list_popup"
    data-bind="stopBinding: true"
    class="popup_area"
  >

    <div
      id="popup"
      class="popup ui-draggable"
      style="display: none"
    >

    </div>

  </div>

</template>
